// src/components/AboutUs.js
import React, { useState, useEffect } from "react";
import "./AboutUs.css"; // Import a CSS file for styling
import repairImage from "../../assets/gifImage.gif"; // Ensure you have this image in your assets folder
import { Box } from "@mui/material";
import CountUp from "react-countup";

const calculateJobsDone = () => {
  const startDate = new Date("2024-07-09"); // Fixed start date
  const currentDate = new Date();
  const timeDifference = currentDate - startDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
  const baseNumber = 32026;
  return baseNumber + daysDifference * 3;
};

const AboutUs = () => {
  const [jobsDone, setJobsDone] = useState(calculateJobsDone());

  useEffect(() => {
    const interval = setInterval(() => {
      setJobsDone(calculateJobsDone());
    }, 24 * 60 * 60 * 1000); // Update every 24 hours
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="about-us-container">
      <Box className="about-us-text">
        <h2>ABOUT US</h2>
        <h1>Why Choose Sky Appliance Repair</h1>
        <p>
          At Sky Appliance Repair Company, we guarantee peace of mind and
          efficient service for your household appliances. Our team of skilled
          technicians is well-trained to handle various appliance brands and
          models, offering thorough diagnostics and effective repairs.
          Additionally, choosing us can lead to long-term savings by extending
          the lifespan of your appliances through expert maintenance and
          repairs. We prioritize customer satisfaction and maintain transparent
          pricing, making us the top choice for all your appliance repair needs.
        </p>
        <ul>
          <li>→ Certified Technicians</li>
          <li>→ Prompt Service</li>
          <li>→ Extensive Brand Knowledge</li>
          <li>→ Thorough Diagnostics</li>
        </ul>
      </Box>
      <Box className="about-us-image">
        <img src={repairImage} alt="Appliance Repair" />
        <Box className="jobs-done">
          <CountUp end={jobsDone} duration={5} />
          <p>Jobs Done</p>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
