import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#1d2150",
        height: "50px",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "20px",
        color: " #6c7176",
      }}
    >
      <Typography textAlign="center" color="textSecondary">
        © 2024
        <Typography
          component="span"
          sx={{
            color: "#ffffff",
            fontWeight: "bold",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Sky Appliance Repair
        </Typography>
        All rights reserved
      </Typography>
    </Box>
  );
};

Footer.propTypes = {};

export default Footer;
