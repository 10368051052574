import React, { useState } from "react";
import { SpeedDial } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const ChatButton = () => {
  const [isSpeedDialOpen, setSpeedDialOpen] = useState(false);

  const handleSpeedDialOpen = () => {
    setSpeedDialOpen(true);
  };

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false);
  };

  const handlePhoneButtonClick = () => {
    window.open("tel:(224) 281-7766");
    handleSpeedDialClose();
  };

  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      style={{ zIndex: "9998" }}
      icon={
        <LocalPhoneIcon
          sx={{
            fontSize: "40px",
            backgroundColor: "#040a4f",
            borderRadius: "50%",
            padding: "15px",
            color: "white",
          }}
          onClick={handlePhoneButtonClick}
        />
      }
      onClose={handleSpeedDialClose}
      onOpen={handleSpeedDialOpen}
      open={isSpeedDialOpen}
      direction="up"
      sx={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 1000,
        color: "#5FC4EA",
        boxShadow: "none !important",
      }}
      onMouseEnter={handleSpeedDialOpen}
      onMouseLeave={handleSpeedDialClose}
    />
  );
};

export default ChatButton;
