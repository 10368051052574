import React from "react";
import "./Header.css";
import { Box, useMediaQuery } from "@mui/material";
import AppBar from "../AppBar/AppBar";

const Header = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleContactUsClick = () => {
    window.open("tel:(224) 281-7766");
  };

  return (
    <Box>
      <AppBar />
      <Box className="header">
        <Box className="header-content">
          <h1>Sky Appliance Repair</h1>
          {!isMobile && (
            <p color="white !important">
              Sky Appliance Repair is a reputable company renowned for its
              expertise and efficiency in fixing household appliances.
            </p>
          )}
          <Box className="header-buttons">
            <button
              href="tel:(224) 281-7766"
              className="contact-us"
              onClick={handleContactUsClick}
            >
              Contact Us
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
