import React from "react";
import "./ReasonsToChoose.css";
import image from "../../assets/refrigerator-repairing-services-500x500.webp";
import houseRepairIcon from "../../assets/home-icon.png";
import toolsIcon from "../../assets/tools.png";
import priceTagIcon from "../../assets/price.png";
import customerServiceIcon from "../../assets/customer-service.png";
import warrantyIcon from "../../assets/warranty.png";
import safetyIcon from "../../assets/safety.png";
import { Box } from "@mui/material";

const reasons = [
  {
    icon: houseRepairIcon,
    title: "Customer Satisfaction Guarantee",
    description:
      "Our commitment to customer satisfaction drives everything we do, ensuring that your experience with us is positive and hassle-free.",
  },
  {
    icon: toolsIcon,
    title: "Experienced Technicians",
    description:
      "Our team comprises certified technicians who possess the expertise to diagnose and repair a wide range of appliance issues.",
  },
  {
    icon: priceTagIcon,
    title: "Transparent Pricing",
    description:
      "Our pricing is transparent and competitive, with no hidden fees or surprises, allowing you to budget effectively for your appliance repairs.",
  },
  {
    icon: customerServiceIcon,
    title: "Friendly Customer Support",
    description:
      "We offer friendly customer support, ready to assist you with any question or guidance you may need from the moment you contact us.",
  },
  {
    icon: warrantyIcon,
    title: "Warranty Coverage",
    description:
      "We offer warranty coverage on both parts and labor, providing added assurance and peace of mind for our customers.",
  },
  {
    icon: safetyIcon,
    title: "Commitment to Safety",
    description:
      "We strictly adhere to industry standards and safety protocols during repairs to ensure your well-being and protect your property.",
  },
];

const ReasonsToChoose = () => {
  return (
    <Box className="reasons-container">
      <h2 className="reasons-title">6 Reasons To Choose Our Company</h2>
      <Box className="reasons-content">
        <Box className="reasons-grid">
          {reasons.slice(0, 3).map((reason, index) => (
            <Box key={index} className="reason-card">
              <Box className="icon">
                <img src={reason.icon} alt={`${reason.title} icon`} />
              </Box>
              <h3>{reason.title}</h3>
              <p>{reason.description}</p>
            </Box>
          ))}
        </Box>
        <Box className="image-container">
          <img src={image} alt="Technician" className="center-image" />
        </Box>
        <Box className="reasons-grid">
          {reasons.slice(3).map((reason, index) => (
            <Box key={index} className="reason-card">
              <Box className="icon">
                <img
                  style={{ backgroundColor: "#1d61d7" }}
                  src={reason.icon}
                  alt={`${reason.title} icon`}
                />
              </Box>
              <h3>{reason.title}</h3>
              <p>{reason.description}</p>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReasonsToChoose;
