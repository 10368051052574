// src/components/ServiceCard.js
import React from "react";
import "./ServiceCard.css"; // Import a CSS file for styling

const ServiceCard = ({ image, title }) => {
  return (
    <div className="service-card">
      <img src={image} alt={title} className="service-image" />
      <h3 className="service-title">{title}</h3>
    </div>
  );
};

export default ServiceCard;
