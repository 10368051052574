import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { Box, Typography, useMediaQuery } from "@mui/material";
import ReviewCard from "./ReviewCard";

const Reviews = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const list = [
    {
      id: 1,
      author: " Sheila Peters from Evanston ",
      quote:
        "I had a fantastic experience with this company. The technician arrived on time, diagnosed the problem with my refrigerator quickly, and had it running like new in no time. Highly recommend their services!",
    },
    {
      id: 2,
      author: "Colleen Garcia form Oak lawn ",
      quote:
        "Exceptional service! The staff was friendly and professional. They repaired my washing machine efficiently and at a very reasonable price. I will definitely call them again for any future appliance issues.",
    },
    {
      id: 4,
      author: " Dawn Woffer from Carpentersville ",
      quote:
        "Outstanding experience! They went above and beyond to repair my dishwasher. The technician explained everything clearly and ensured the job was done right. Excellent customer service and expertise.",
    },
    {
      id: 5,
      author: " Sami Ayyad from Chicago ",
      quote:
        "Highly impressed with the quality of service. The technician was punctual, professional, and very efficient. My dryer is working perfectly now. I appreciate their attention to detail and commitment to customer satisfaction.",
    },
    {
      id: 6,
      author: "Latoya Hazelwood from Chicago",
      quote:
        "Top-notch service from start to finish. They responded promptly to my inquiry, scheduled a convenient appointment, and fixed my oven perfectly. The technician was knowledgeable and courteous. Couldn't be happier with the outcome.",
    },
  ];

  return (
    <Box sx={{ px: { xs: "16px", md: "100px" }, mb: "100px" }}>
      <Typography
        variant="h4"
        sx={{ my: "80px", textAlign: "center", color: "black" }}
      >
        OUR HAPPY CUSTOMERS
      </Typography>
      <Swiper
        loop={true}
        loopedSlides={list.length}
        initialSlide={3}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={isMobile ? 1 : 3}
        navigation={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={isMobile ? false : true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
      >
        {list.map((item) => (
          <SwiperSlide key={item.id}>
            <ReviewCard review={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Reviews;
