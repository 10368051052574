// src/components/Services.js
import React from "react";
import ServiceCard from "./ServiceCard";
import fridgeImage from "../../assets/refrigerator.png";
import freezerImage from "../../assets/freezer.png";
import wineCoolerImage from "../../assets/wine-cooler.png";
import iceMachineImage from "../../assets/ice-machine.png";
import cooktop from "../../assets/cooktop.png";
import dryer from "../../assets/dryer.png";
import oven from "../../assets/oven.png";
import hood from "../../assets/hood.png";
import stove from "../../assets/stove.png";
import washer from "../../assets/washer.png";
import dishwasher from "../../assets/dishwasher.png";
import microwave from "../../assets/microwave.png";
import "./Services.css";
import { Box } from "@mui/material";

const Services = () => {
  const services = [
    { image: fridgeImage, title: "Refrigerator Repair" },
    { image: freezerImage, title: "Freezer Repair" },
    { image: wineCoolerImage, title: "Wine Cooler Repair" },
    { image: iceMachineImage, title: "Ice Machine Repair" },
    { image: stove, title: "Stove Repair" },
    { image: oven, title: "Oven Repair" },
    { image: cooktop, title: "Cooktop Repair" },
    { image: microwave, title: "Microwave Repair" },
    { image: hood, title: "Hood Repair" },
    { image: dishwasher, title: "Dishwasher Repair" },
    { image: washer, title: "Washer Repair" },
    { image: dryer, title: "Dryer Repair" },
  ];

  return (
    <Box className="services-container" textAlign={"center"}>
      <h4 style={{ marginBottom: "-35px" }}>OUR SERVICES</h4>
      <h2 className="services-title">
        We Provide Best Quality <br /> Appliance Repair Services
      </h2>
      <Box className="services-grid">
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            image={service.image}
            title={service.title}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Services;
