import React, { useState } from "react";
import "./AppBar.css";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone"; // Import PhoneIcon
import logo from "../../assets/sky-applince.jpg";

const AppBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { label: "Home", href: "#home" },
    { label: "Services", href: "#services" },
    { label: "Testimonials", href: "#testimonials" },
    { label: "Projects", href: "#projects" },
    { label: "Blog", href: "#blog" },
    { label: "FAQ", href: "#faq" },
    { label: "About Us", href: "#about-us" },
    { label: "Contact Us", href: "#contact-us" },
  ];

  return (
    <Box className="appbar" sx={{ backgroundColor: "#070a4f" }}>
      <Box className="appbar-logo" sx={{ borderRadius: "50px" }}>
        <img className="logo" src={logo} alt="logo" height={70} width={70} />
      </Box>
      {!isMobile && (
        <Box className="appbar-nav">
          {menuItems.map((item) => (
            <a key={item.href} href={item.href}>
              {item.label}
            </a>
          ))}
        </Box>
      )}
      {!isMobile && (
        <Box className="appbar-contact">
          <Box className="appbar-phone">
            <PhoneIcon className="phone-icon" /> {/* Phone Icon */}
            <Typography variant="body1">
              <a
                href="tel:+12242817766"
                style={{ color: "white", textDecoration: "none" }}
              >
                (224) 281-7766
              </a>
            </Typography>
          </Box>
          <Box className="appbar-search">
            <i className="fas fa-search"></i>
          </Box>
        </Box>
      )}
      {isMobile && (
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box>
            <IconButton className="appbar-burger" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
      )}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="drawer"
      >
        <Box className="drawer-header">
          <IconButton onClick={toggleDrawer(false)} className="drawer-close">
            <CloseIcon />
          </IconButton>
        </Box>
        <List className="drawer-list">
          {menuItems.map((item) => (
            <ListItem button key={item.href} onClick={toggleDrawer(false)}>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default AppBar;
